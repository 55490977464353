import React from "react"
// import { Link } from "gatsby"
// import classNames from "classnames"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/banner"
import data from "../json/advance.json"

const AdvancePage = function () {
  return (
    <Layout cur="/advance/" stickyHeader>
      <SEO
        title="选择酷课约课系统的优势"
        keywords={[
          "约课系统优势",
          "微信小程序约课",
          "公众号模板消息",
          "在线支付约课",
          "会员管理优势",
          "课程预约优势",
          "培训机构解决方案",
          "约课系统性价比"
        ]}
        description="酷课约课系统具备快速创建微信小程序、公众号消息通知、在线支付、会员管理等优势。采用灵活的日租计费模式，提供7x12小时专业客服支持，永久免费升级维护，助力培训机构降本增效。"
      />

      {data.map((v, i) => (
        <Banner key={i} v={v} i={i} h1 />
      ))}
    </Layout>
  )
}

export default AdvancePage
